/* eslint-disable */
import { useState, useEffect } from "react";
import {
  MdBarChart,
  MdHome,
  MdNotificationsNone,
  MdOutlineShoppingCart,
  MdPerson,
  MdSettings,
  MdSpaceDashboard,
  MdAllInbox,
  MdOutlineAccountTree,
} from "react-icons/md";

import { routesNames } from "utils/Constants";
import { NavLink } from "react-router-dom";
import { Menu } from 'antd';
import usePublications from "hooks/usePublications";
import { useAuth } from "hooks/useAuth";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const SidebarLinks = (props) => {
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const { fetchData } = usePublications();
  const [pendientes, setPendientes] = useState(0);
  const { userData, logout } = useAuth()

  const fetchPublications = async () => {
    let tempPendientes = 0;
    const data = await fetchData(null, { userId: null }, true)

    if (data) {
      tempPendientes = data?.data?.map((item) => item.aprobada == 0 && item.activa == 1 && item.finalizada == 0 && item.pausada == 0 && item.vencimiento?.split(' ')[0] > new Date().toISOString().split('T')[0]).reduce((a, b) => a + b, 0);
    }

    setPendientes(tempPendientes);
  };

  useEffect(() => {
    fetchPublications();
  }, []);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    // if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      if (latestOpenKey === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const items = [
    getItem(
      <NavLink
        to={routesNames.DASHBOARD}
        className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
      >
        Dashboard general
      </NavLink>,
      '1',
      <MdHome className="h-6 w-6" />
    ),
    getItem(
      <NavLink
        to={routesNames.REQUESTS}
        className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
      >
        Pedidos
      </NavLink>,
      '2',
      <MdOutlineShoppingCart className="h-6 w-6" />
    ),
    getItem(
      <NavLink
        to={routesNames.PUBLICATIONS}
        className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
      >
        <div className="flex flex-row justify-between items-center gap-2">
          Publicaciones <span className="text-white text-sm bg-red-500 rounded-full px-2">{pendientes > 0 ? `${pendientes}` : null}</span>
        </div>
      </NavLink>,
      '3a',
      <MdAllInbox className="h-6 w-6" />
    ),
    getItem('Contable', '3', <MdBarChart className="h-6 w-6" />),
    getItem(
      <NavLink
        to={routesNames.USERS}
        className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
      >
        Usuarios
      </NavLink>,
      '4',
      <MdPerson className="h-6 w-6" />
    ),
    getItem(
      <NavLink
        to={routesNames.ESPECIALIDADES}
        className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
      >
        Especialidades
      </NavLink>,
      '5',
      <MdOutlineAccountTree className="h-6 w-6" />
    ),
    getItem('CMS', '6', <MdSpaceDashboard className="h-6 w-6" />, [
      getItem(
        <NavLink
          to={routesNames.FAQS}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Preguntas frecuentes
        </NavLink>,
        'sub51'
      ),
      getItem(
        <NavLink
          to={routesNames.BLOCKS}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Bloques y textos
        </NavLink>,
        'sub54'
      ),
      getItem(
        <NavLink
          to={routesNames.PAGES}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Páginas
        </NavLink>,
        'sub52'
      ),
      getItem(
        <NavLink
          to={routesNames.CONTACT}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Información de contacto
        </NavLink>,
        'sub53'
      ),
    ]),
    getItem('Ajustes', '7', <MdSettings className="h-6 w-6" />, [
      getItem(
        <NavLink
          to={routesNames.SETTINGS_PUBLICATIONS}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Publicaciones
        </NavLink>,
        'sub61'
      ),
      getItem(
        <NavLink
          to={routesNames.SETTINGS_PLANS}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Planes
        </NavLink>,
        'sub62'
      ),
      getItem(
        <NavLink
          to={routesNames.TRANSFER_DATA}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Datos de transferencia
        </NavLink>,
        'sub63'
      ),
    ]),
    getItem('Notificaciones', '8', <MdNotificationsNone className="h-6 w-6" />, [
      getItem(
        <NavLink
          to={routesNames.NOTIFICATIONS_ADMIN}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Administrador
        </NavLink>,
        'sub71'
      ),
      getItem(
        <NavLink
          to={routesNames.NOTIFICATIONS_INST}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Institución
        </NavLink>,
        'sub72'
      ),
      getItem(
        <NavLink
          to={routesNames.NOTIFICATIONS_USER}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Postulante
        </NavLink>,
        'sub73'
      ),
      getItem(
        <NavLink
          to={routesNames.NOTIFICATIONS_CUSTOM}
          className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}
        >
          Personalizadas
        </NavLink>,
        'sub74'
      ),
    ]),
  ];

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{
        width: 256,
      }}
      items={items}
    />
  );
}

export default SidebarLinks;
