import React, { useEffect, useState } from 'react';
import { Table, Card } from 'antd';
import useUsers from "hooks/useUsers";
import { states } from 'utils/Tables';

const columns = [
    {
        title: 'Provincia',
        dataIndex: 'province',
        key: 'province',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Postulantes',
        dataIndex: 'postulant',
        key: 'postulant',
    },
    {
        title: 'Instituciones',
        dataIndex: 'institution',
        key: 'institution',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
    },
];

const agregateDataByProvince = (postulantes, instituciones) => {
    const provinceMap = {};

    states.forEach(({ name, value }) => {
        provinceMap[value] = { province: name, postulant: 0, institution: 0, total: 0 };
    });

    postulantes.forEach(({ cuenta }) => {
        const province = cuenta?.provincia;
        if (provinceMap[province]) {
            provinceMap[province].postulant += 1;
            provinceMap[province].total += 1;
        }
    });

    instituciones.forEach(({ cuenta }) => {
        const province = cuenta?.provincia;
        if (provinceMap[province]) {
            provinceMap[province].institution += 1;
            provinceMap[province].total += 1;
        }
    });

    return Object.values(provinceMap);
};

const ProvincesTable = () => {
    const { isLoading, response, fetchData } = useUsers();
    const [postulantes, setPostulantes] = useState([]);
    const [instituciones, setInstituciones] = useState([]);
    const [tableData, setTableData] = useState([]);

    const fetchUsers = async () => {
        const data = await fetchData(null, true);

        const filteredPostulantes = data.filter(user => user?.type === 'P');
        const filteredInstituciones = data.filter(user => user?.type === 'I');

        setPostulantes(filteredPostulantes);
        setInstituciones(filteredInstituciones);

        // Generar los datos para la tabla
        const agregatedData = agregateDataByProvince(filteredPostulantes, filteredInstituciones);
        const temp = agregatedData
            .filter((province) => province.total !== 0)
            .sort((a, b) => b.total - a.total)
        setTableData(temp)
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Card className="w-full h-full sm:overflow-auto px-6">
            <div className="text-xl font-bold text-navy-700 dark:text-white mb-5">
                Usuarios por región
            </div>
            <Table
                columns={columns}
                dataSource={tableData}
                loading={isLoading}
                rowKey={(record) => record.province}
            />
        </Card>
    );
};

export default ProvincesTable;
