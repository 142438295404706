import Button from 'components/Button';
import InputUseForm from "components/InputUseForm";
import SelectUseForm from "components/SelectUseForm";
import usePublications from "hooks/usePublications";

import { jornadas, niveles, posiciones, states, tipoContratacion, turnos } from "utils/Tables";
import Editor from 'components/editor';
import Loader from "components/loader";
import { routesNames } from "utils/Constants";
import { getNameFromObject } from "utils/Utils";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQuill } from 'react-quilljs';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllEspecialidades } from '../../../services/Especialidades';
import AbilitiesTreeSelect from 'components/abilities/AbilitiesTreeSelect';
import { Space, Switch } from 'antd';


export default function EditPublicationPage() {
    const navigate = useNavigate();

    let { id } = useParams();

    const theme = 'snow';
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ],
    };

    const placeholder = 'Detalle de la oferta...';
    const { quill, quillRef } = useQuill({ theme, modules, placeholder });
    const [locations, setLocations] = useState([])
    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm();

    const { isLoading, response: publicationData, fetchData, error, store, fetchLocationsByAccount } = usePublications()
    const [especialidadesCascader, setEspecialidadesCascader] = useState([]);
    const [allEspecialidades, setAllEspecialidades] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    const fetchLocations = async () => {
        const response = await fetchLocationsByAccount(publicationData?.cuenta?.user_id);

        if (response) {
            let data = [];
            data.push({
                name: `Sin ubicación especifica`,
                value: "null"
            })

            response.map((item) => {
                data.push({
                    name: `${item.domicilio}, ${item.localidad}, ${getNameFromObject(states, item.provincia)}`,
                    value: item.id
                })
            })

            setLocations(data)
        }
    }

    useEffect(() => {
        // setSelectedValue(publicationData?.especialidad_id)

        const fetchData = async () => {
            try {
                const [especialidadesOptions] = await Promise.all([
                    getAllEspecialidades()
                ]);
                setAllEspecialidades(especialidadesOptions);
            } catch (error) {
                console.log("Ocurrió un error al cargar los datos")
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (publicationData?.cuenta) {
            setValue("ubicacion_id", publicationData?.ubicacion_id == null ? "null" : publicationData?.ubicacion_id)
        }
    }, [locations])

    useEffect(() => {
        fetchData(id)
    }, [id])

    useEffect(() => {
        getEspecialidadesOptions()

        if (locations.length == 0 && publicationData?.cuenta) {
            fetchLocations()
        }

        if (id) {
            if (publicationData?.cuenta) {
                setValue("nombre", publicationData?.nombre)
                setValue("nivel", publicationData?.nivel)
                setValue("jornada", publicationData?.jornada)
                setValue("turno", publicationData?.turno)
                setValue("posicion", publicationData?.posicion)
                setValue("especialidad_id", publicationData?.especialidad_id)
                // setValue("tipo_contratacion", publicationData.tipo_contratacion)
                setValue("ubicacion_id", publicationData?.ubicacion_id == null ? "null" : publicationData?.ubicacion_id)
                setValue("liderazgo", publicationData?.liderazgo)
                // editorRef.current?.setContent(publicationData.descripcion)
                if (quill) {
                    quill.clipboard.dangerouslyPasteHTML(publicationData?.descripcion);
                }
            }
        }
    }, [publicationData])


    const getEspecialidadesOptions = async () => {
        const data = await getAllEspecialidades()
        specialtiesCascader(data)
    }


    const specialtiesCascader = (data) => {
        const specialtyMap = data.reduce((acc, specialty) => {
            acc[specialty.id] = specialty;
            return acc;
        }, {});

        const getFullName = (specialty) => {
            const names = [];
            let currentSpecialty = specialty;

            while (currentSpecialty) {
                names.unshift(currentSpecialty.especialidad);
                currentSpecialty = currentSpecialty.parent_id
                    ? specialtyMap[currentSpecialty.parent_id]
                    : null;
            }

            return names.join(' / ');
        };

        const formattedCategories = data.map((specialty) => ({
            name: getFullName(specialty),
            value: specialty.id,
        }));

        setEspecialidadesCascader(formattedCategories);
    }

    const handleEspecialidad = (value) => {
        setSelectedValue(value)
        setValue("especialidad_id", value)
    }


    const onSubmit = async (data) => {

        data.descripcion = quillRef.current.firstChild.innerHTML;
        data.user_id = publicationData?.cuenta?.user_id
        data.vencimiento = publicationData?.vencimiento
        // const vencimiento = new Date(data.vencimiento)
        // data.vencimiento = `${vencimiento.getFullYear()}-${getFullMonth(vencimiento)}-${getFullDay(vencimiento, true)} 00:00:01`
        data.ubicacion_id = data.ubicacion_id == "null" ? null : data.ubicacion_id

        await store(data, (response) => {
            if (!error) {

                reset()

                setTimeout(() => {
                    navigate(`${routesNames.PUBLICATION}/${id}`)
                }, [1000])

            }
        }, publicationData?.id)
    }

    const clearData = () => {
        reset()
        navigate(`${routesNames.PUBLICATION}/${id}`)
    }

    return (
        <div className="w-full flex min-h-40 rounded-b-md items-start flex-col pb-10">

            <form onSubmit={handleSubmit(onSubmit)} className=" w-full bg-white rounded-b-md p-8 transition-all">

                {id && <span className=' bg-aprobado block text-center py-2 text-white text-sm'>Editando publicación</span>}

                <div className='text-start flex flex-col mt-4'>
                    <Space>
                        <span className='text-sm font-bold'>Esta es una posición de liderazgo</span>
                        <Controller
                            name='liderazgo'
                            control={control}
                            render={({ field }) => (
                                <Switch
                                    {...field}
                                    defaultChecked={false}
                                />

                            )}
                        />
                    </Space>
                </div>

                <InputUseForm
                    label="Título"
                    name="nombre"
                    register={register}
                    errors={errors}
                    placeholder="Ingrese el título de la publicación"
                    rules={{ required: "El título es requerido" }}
                    className="w-full"
                />

                <div className="flex items-baseline justify-between sm:gap-4 sm:flex-row flex-col">

                    <SelectUseForm
                        label="Nivel"
                        name="nivel"
                        register={register}
                        errors={errors}
                        rules={{ required: "Debe ingresar el nivel" }}
                        className="w-full "
                        options={niveles}
                    />

                    <SelectUseForm
                        label="Jornada"
                        name="jornada"
                        register={register}
                        errors={errors}
                        rules={{ required: "Debe ingresar el tipo de jornada" }}
                        className="w-full "
                        options={jornadas}
                    />
                </div>

                <div className="flex items-baseline justify-between sm:gap-4 sm:flex-row flex-col">

                    {/* <InputUseForm
                        label="Vencimiento"
                        name="vencimiento"
                        register={register}
                        errors={errors}
                        placeholder="DD/MM/AAAA"
                        rules={{ required: "La fecha de vencimiento es requerida" }}
                        className="w-full"
                        type="date"
                        format="DD/MM/YYYY"
                        // picker="month"
                        control={control}
                    /> */}

                    <SelectUseForm
                        label="Turno"
                        name="turno"
                        register={register}
                        errors={errors}
                        rules={{ required: "Debe ingresar el turno" }}
                        className="w-full "
                        options={turnos}
                    />

                    <SelectUseForm
                        label="Posición"
                        name="posicion"
                        register={register}
                        errors={errors}
                        rules={{ required: "Debe ingresar la posición" }}
                        className="w-full "
                        options={posiciones}
                    />
                </div>

                {/* <SelectUseForm
                    label="Tipo de contratación"
                    name="tipo_contratacion"
                    register={register}
                    errors={errors}
                    rules={{ required: "Debe ingresar el tipo de contratación" }}
                    className="w-full "
                    options={tipoContratacion}
                /> */}

                <span className="text-start block font-semibold mb-2 mt-2">Especialidad buscada</span>
                <AbilitiesTreeSelect abilities={allEspecialidades} onChange={handleEspecialidad} selectedValue={publicationData?.especialidad_id} />
                {/* <SelectUseForm
                    label="Especialidad buscada"
                    name="especialidad_id"
                    register={register}
                    errors={errors}
                    rules={{ required: "Debe ingresar al menos una especialidad" }}
                    className="w-full"
                    options={especialidadesCascader}
                /> */}

                <SelectUseForm
                    label="Ubicación"
                    name="ubicacion_id"
                    register={register}
                    errors={errors}
                    rules={{ required: "Debe ingresar la ubicación" }}
                    className="w-full "
                    options={locations}
                />

                <div className="flex flex-col items-start w-full">
                    <label className="text-start block font-semibold mb-2 mt-5">Descripción<span className="text-red-500">*</span></label>
                    {/* <Editor editorRef={editorRef} /> */}
                    <Editor classname="h-60" quillRef={quillRef} />
                    {errors.descripcion && <span className="text-red-500 text-sm italic text-start w-full">{errors.descripcion.message}</span>}
                </div>

                {error && <span className='text-sm text-red-600 font-semibold block my-2 text-center'>{error}</span>}

                <div className="flex items-end w-full justify-end gap-4 mt-14">
                    <Button type="button" label="Cancelar" onClick={clearData} className="!bg-gray-50" />
                    {id ?
                        <Button isLoading={isLoading} type="submit" label="Editar publicación" />
                        :
                        <Button isLoading={isLoading} type="submit" label="Publicar" />
                    }
                </div>
            </form>
        </div >
    )

}
