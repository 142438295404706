import MiniCalendar from "components/calendar/MiniCalendar";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import SelectUseForm from "components/SelectUseForm";
import { useForm, Controller } from "react-hook-form";
import dayjs from 'dayjs';
import ExportExcel from "components/excel/ExportExcel";
import { IoMdHome, IoMdDocument, IoMdSchool } from "react-icons/io";
import { MdBarChart, MdDocumentScanner } from "react-icons/md";
import { FaSchool, FaUsers, FaRobot } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import { Button } from "antd"
import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import useStats from "hooks/useStats";
import { useEffect, useState } from "react";
import Loader from "components/loader";
import usePublications from "hooks/usePublications";
import useUsers from "hooks/useUsers";
import ProvincesTable from "./components/ProvincesTable";


const Dashboard = () => {
  const { isLoading, fetchData } = useStats('main')
  const [stats, setStats] = useState()
  const { isLoading: isLoadingPublications, response, fetchData: fetchPublications, getPostulateds } = usePublications()
  const [totalPostulaciones, setTotalPostulaciones] = useState([])
  const [totalNoEspontaneas, setTotalNoEspontaneas] = useState([])
  const [totalEspontaneas, setTotalEspontaneas] = useState([])
  const [totalInstCPubActivas, setTotalInstCPubActivas] = useState([])
  const [totalPostulantes, seTotalPostulantes] = useState(0)
  const [totalPostPublicos, seTotalPostPublicos] = useState(0)
  const [totalUsers, setTotalUsers] = useState([])
  const [perfilesAbiertos, setPerfilesAbiertos] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [cvsConIA, setCvsConIA] = useState(0)
  const [cvsIncompletos, setCvsIncompletos] = useState(0)
  const [currentDesde, setCurrentDesde] = useState(dayjs(new Date()).format('YYYY-MM'))
  const [currentHasta, setCurrentHasta] = useState(dayjs(new Date()).format('YYYY-MM'))
  const [totalPublicaciones, setTotalPublicaciones] = useState([])
  const [dataExcel, setDataExcel] = useState([]);
  const [visible, setVisible] = useState(false);

  const [totalInst, setTotalInst] = useState(0)
  const [totalInstFiltrado, setTotalInstFiltrado] = useState(0)
  const [totalPubAct, setTotalPubAct] = useState(0)
  const [totalPubALaFecha, setTotalPubALaFecha] = useState(0)
  const [insPubAct, setInsPubAct] = useState('')
  const [totPost, setTotPost] = useState(0)
  const [totPostPub, setTotPostPub] = useState('')
  const [totPostu, setTotPostu] = useState(0)
  const [totEspon, setTotEspon] = useState('')
  const [totNoEspon, setTotNoEspon] = useState('')

  const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm();
  const { isLoading: isLoadingUsers, response: users, fetchData: getUsers } = useUsers();

  const meses = [
    { name: "Noviembre 2023", value: "2023-11" },
    { name: "Diciembre 2023", value: "2023-12" },
    { name: "Enero 2024", value: "2024-01" },
    { name: "Febrero 2024", value: "2024-02" },
    { name: "Marzo 2024", value: "2024-03" },
    { name: "Abril 2024", value: "2024-04" },
    { name: "Mayo 2024", value: "2024-05" },
    { name: "Junio 2024", value: "2024-06" },
    { name: "Julio 2024", value: "2024-07" },
    { name: "Agosto 2024", value: "2024-08" },
    { name: "Septiembre 2024", value: "2024-09" },
    { name: "Octubre 2024", value: "2024-10" },
    { name: "Noviembre 2024", value: "2024-11" },
    { name: "Diciembre 2024", value: "2024-12" },
  ]


  const fetchStats = async () => {
    const data = await fetchData()
    setStats(data)
  }

  const fetchGetPublications = async () => {
    const data = await fetchPublications(null, {}, true)
    const fechaActual = new Date().toISOString().split('T')[0];

    // console.log(data.data.filter(pub => pub.cuenta_id == 2))
    let totalpubActivas = data?.data?.filter(pub => pub?.activa === 1 && pub?.aprobada === 1)

    const instituciones = Object.values(
      totalpubActivas.reduce((acc, pub) => {
        const institucionId = pub.cuenta.id;
        acc[institucionId] = acc[institucionId] || { ...pub.cuenta, totalpubActivas: [] };
        acc[institucionId].totalpubActivas.push(pub);
        return acc;
      }, {})
    );

    setTotalPublicaciones(data.data)
    setTotalInstCPubActivas(instituciones);
  }


  const institucionesPublicacioneActivas = (data) => {
    const institucionesUnicas = new Set(data.map(pub => pub?.cuenta?.user_id));
    const totalInstituciones = institucionesUnicas.size;

    return totalInstituciones;
  };


  const fetchDataPostulations = async () => {
    setLoadingData(true)
    const data = await getPostulateds(null, 5, true)

    setTotalEspontaneas(data.filter(publication => publication?.publicacion_id === null))
    setTotalNoEspontaneas(data.filter(publication => publication?.publicacion_id !== null))
    setTotalPostulaciones(data)

    setLoadingData(false)
  }

  const fetchUsers = async () => {
    const data = await getUsers(null, true)

    setCvsConIA(data.filter(user => user?.cuenta?.disponible_ia === -1).length)
    setTotalUsers(data)
    setCvsIncompletos(data.filter(user => user?.type === 'P').filter(user => user?.cuenta?.fecha_perfil_completo === null).length)
    seTotalPostulantes(data.filter(user => user?.type === 'P').length)
    seTotalPostPublicos(data.filter(user => user?.type === 'P').filter(user => user?.cuenta?.perfil_publico === 1).length)
  }

  function isDateInRange(createdAt, start, end) {

    if (createdAt !== null || createdAt !== undefined || createdAt !== '') {
      const startDate = new Date(`${start}-01T00:00:00`);
      const endDate = new Date(`${end}-30T00:00:00`);
      endDate.setMonth(endDate.getMonth() + 1); // Agregar un mes
      endDate.setDate(0); // Fijar el último día del mes anterior

      // Convertir la fecha de created_at a Date
      const createdDate = new Date(createdAt);

      return createdDate >= startDate && createdDate <= endDate;
    }

  }

  const handleFilterRange = () => {

    if (currentHasta < currentDesde) {
      return alert('La fecha hasta no puede ser menor a la fecha desde')
    }

    setVisible(true)
    setTotalInst(totalUsers.filter(user => user?.type === 'I').filter(cuenta => isDateInRange(cuenta?.cuenta?.created_at, '2023-11', currentHasta)).length)
    setTotalInstFiltrado(totalUsers.filter(user => user?.type === 'I').filter(cuenta => isDateInRange(cuenta?.cuenta?.created_at, currentDesde, currentHasta)).length)
    setTotalPubAct(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta)).filter(pub => pub?.activa === 1 && pub?.aprobada === 1).length)
    setTotalPubALaFecha(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, '2023-11', currentHasta)).filter(pub => pub?.activa === 1 && pub?.aprobada === 1).length)
    setInsPubAct((institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta))) * 100 / totalUsers.filter(user => user?.type === 'I').filter(cuenta => isDateInRange(cuenta?.cuenta?.created_at, '2023-11', currentHasta)).length).toFixed(2) + '% ' + '(' + institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta))).toLocaleString('es-AR') + ')')
    setTotPost(totalUsers.filter(user => user?.type === 'P').filter(cuenta => isDateInRange(cuenta?.cuenta?.created_at, currentDesde, currentHasta)).length)
    setTotPostPub(((totalPostPublicos / totalPostulantes) * 100)?.toFixed(2) + '% ' + '(' + totalPostPublicos?.toLocaleString('es-AR') + ')')
    setTotPostu(totalPostulaciones?.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length || 0)
    setTotEspon(totalEspontaneas?.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length + ' ' + '(' + (totalEspontaneas.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length * 100 / totalPostulaciones.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length).toFixed(2) + '%' + ')')
    setTotNoEspon(totalNoEspontaneas?.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length + ' ' + '(' + (totalEspontaneas.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length * 100 / totalNoEspontaneas.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length).toFixed(2) + '%' + ')')

    const data = {
      Fecha: dayjs(new Date()).format('DD/MM/YYYY'),
      Total_instituciones: totalUsers.filter(user => user?.type === 'I').filter(cuenta => isDateInRange(cuenta?.cuenta?.created_at, '2023-11', currentHasta)).length,
      Registradas: totalUsers.filter(user => user?.type === 'I').filter(cuenta => isDateInRange(cuenta?.cuenta?.created_at, currentDesde, currentHasta)).length,
      Publicaciones_a_la_fecha: totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, '2023-11', currentHasta)).filter(pub => pub?.activa === 1 && pub?.aprobada === 1).length,
      Publicaciones_activas: totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta)).filter(pub => pub?.activa === 1 && pub?.aprobada === 1).length,
      Instituciones_con_publicaciones_activas: institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta))).toLocaleString('es-AR'),
      Porcentaje_Instituciones_con_publicaciones_activas: (institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta))) * 100 / totalUsers.filter(user => user?.type === 'I').filter(cuenta => isDateInRange(cuenta?.cuenta?.created_at, '2023-11', currentHasta)).length).toFixed(2) + '% ',
      Total_postulantes: totalUsers.filter(user => user?.type === 'P').filter(cuenta => isDateInRange(cuenta?.cuenta?.created_at, currentDesde, currentHasta)).length,
      Postulaciones_totales: totalPostulaciones?.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length || 0,
      Postulaciones_espontaneas: totalEspontaneas?.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length + ' ' + '(' + (totalEspontaneas.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length * 100 / totalPostulaciones.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length).toFixed(2) + '%' + ')',
      Postulaciones_no_espontaneas: totalNoEspontaneas?.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length + ' ' + '(' + (totalEspontaneas.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length * 100 / totalNoEspontaneas.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length).toFixed(2) + '%' + ')',
      Postulantes_con_perfil_abierto: totalPostPublicos?.toLocaleString('es-AR'),
      Porcentaje_postulantes_con_perfil_abierto: ((totalPostPublicos / totalPostulantes) * 100)?.toFixed(2) + '% ',
      Postulantes_con_CV_con_IA: cvsConIA,
      Postulantes_con_perfil_incompleto: cvsIncompletos,
      Porcentaje_postulantes_con_perfil_incompleto: (cvsIncompletos * 100 / totalPostulantes).toFixed(2) + '%',
    }

    dataExport(data)
  }

  const dataExport = (data) => {

    const exportData = {
      Fecha: data.Fecha,
      Total_instituciones: data.Total_instituciones,
      Registradas: data.Registradas,
      Publicaciones_a_la_fecha: data.Publicaciones_a_la_fecha,
      Publicaciones_activas: data.Publicaciones_activas,
      Instituciones_con_publicaciones_activas: data.Instituciones_con_publicaciones_activas,
      Porcentaje_Instituciones_con_publicaciones_activas: data.Porcentaje_Instituciones_con_publicaciones_activas,
      Total_postulantes: data.Total_postulantes,
      Postulaciones_totales: data.Postulaciones_totales,
      Postulaciones_espontaneas: data.Postulaciones_espontaneas,
      Postulaciones_no_espontaneas: data.Postulaciones_no_espontaneas,
      Postulantes_con_perfil_abierto: data.Postulantes_con_perfil_abierto,
      Porcentaje_postulantes_con_perfil_abierto: data.Porcentaje_postulantes_con_perfil_abierto,
      Postulantes_con_CV_con_IA: data.Postulantes_con_CV_con_IA,
      Postulantes_con_perfil_incompleto: data.Postulantes_con_perfil_incompleto,
      Porcentaje_postulantes_con_perfil_incompleto: data.Porcentaje_postulantes_con_perfil_incompleto
    }

    setDataExcel(exportData)
  }

  const changeDesde = (e) => {
    setCurrentDesde(e.target.value)
  }

  const changeHasta = (e) => {
    setCurrentHasta(e.target.value)
  }

  useEffect(() => {
    fetchStats()
    fetchDataPostulations()
    fetchGetPublications()
    fetchUsers()
    setValue('desde', dayjs(new Date()).format('YYYY-MM'))
    setValue('hasta', dayjs(new Date()).format('YYYY-MM'))
  }, [])


  return (

    <div>
      {/* Card widget */}

      <div className="flex flex-row justify-start items-start gap-2">
        <SelectUseForm
          // label="Desde"
          name="desde"
          register={register}
          onChange={changeDesde}
          errors={errors}
          className="w-[10%]"
          options={meses}
        />
        <SelectUseForm
          // label="Hasta"
          name="hasta"
          register={register}
          onChange={changeHasta}
          errors={errors}
          className="w-[10%]"
          options={meses}
        />

        <div className="flex flex-row justify-between items-center gap-3 mt-1">
          <Button
            onClick={handleFilterRange}
            type="primary"
            className="text-xl primary"
          >
            Buscar
          </Button>
          <div className={`${visible ? 'd-block' : 'hidden'}`}>
            <ExportExcel data={dataExcel} fileName={"Publicaciones_" + new Date().toLocaleDateString()} />
          </div>
        </div>

      </div>




      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-5">
        <Widget
          icon={<FaSchool className="h-6 w-6" />}
          title={"Total instituciones"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : totalInst}
        />

        <Widget
          icon={<FaSchool className="h-6 w-6" />}
          title={"Registradas"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : totalInstFiltrado}
        />

        <Widget
          icon={<IoMdDocument className="h-6 w-6" />}
          title={"Publicaciones a la fecha"}
          subtitle={isLoadingPublications ? <Loader small={true} /> : totalPubALaFecha}
        />

        <Widget
          icon={<IoMdDocument className="h-6 w-6" />}
          title={"Total publicaciones activas"}
          subtitle={isLoadingPublications ? <Loader small={true} /> : totalPubAct}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"% Instituciones con publicaciones activas"}
          subtitle={isLoadingPublications ? <Loader small={true} /> : insPubAct}
        />

      </div>

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-5">
        <Widget
          icon={<IoMdSchool className="h-7 w-7" />}
          title={"Total de postulantes"}
          subtitle={isLoading ? <Loader small={true} /> : totPost}

        />

        <Widget
          icon={<IoDocumentsSharp className="h-7 w-7" />}
          title={"Postulaciones totales"}
          subtitle={loadingData ? <Loader small={true} /> : totPostu}
          secondTitle={"Espontáneas"}
          secondSubtitle={loadingData ? <Loader small={true} /> : totEspon}
          thirdTitle={"Directas"}
          thirdSubtitle={loadingData ? <Loader small={true} /> : totNoEspon}
        />

        <Widget
          icon={<FaUsers className="h-6 w-6" />}
          title={"% Postulantes con perfil abierto"}
          subtitle={loadingData ? <Loader small={true} /> : totPostPub}
        />

        <Widget
          icon={<FaRobot className="h-6 w-6" />}
          title={"Postulantes que cargaron CV con IA"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : cvsConIA}
        />

        <Widget
          icon={<MdDocumentScanner className="h-6 w-6" />}
          title={"Postulantes con perfil incompleto"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : cvsIncompletos + ' ' + '(' + (cvsIncompletos * 100 / totalPostulantes).toFixed(2) + '%' + ')'}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 w-full">
        <TotalSpent users={totalUsers} />
        {/* <WeeklyRevenue /> */}
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5">
        <ComplexTable />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}


        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <MiniCalendar />
        </div>
        <PieChartCard />

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5">

        <CheckTable />
        <ProvincesTable />
      </div>


    </div>
  );
};

export default Dashboard;
