import Card from "components/card";

const Widget = ({ icon, title, subtitle, secondTitle, secondSubtitle, thirdTitle, thirdSubtitle }) => {
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] p-4">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {subtitle}
          </h4>
        </div>

        <div className="h-50 flex w-auto flex-row justify-center">
          <div className="h-50 ml-4 flex w-auto flex-col justify-center">
            <p className="font-dm text-[10px] font-medium text-gray-600">{secondTitle}</p>
            <h4 className="text-sm font-bold text-navy-700 dark:text-white">
              {secondSubtitle}
            </h4>
          </div>

          <div className="h-50 ml-4 flex w-auto flex-col justify-center">
            <p className="font-dm text-[10px] font-medium text-gray-600">{thirdTitle}</p>
            <h4 className="text-sm font-bold text-navy-700 dark:text-white">
              {thirdSubtitle}
            </h4>
          </div>
        </div>

      </div>

    </Card>
  );
};

export default Widget;
