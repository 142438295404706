import { Table } from "antd";
import Card from "components/card";
import Loader from "components/loader";
import usePublications from "hooks/usePublications";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routesNames } from "utils/Constants";
import { getFullDay, getFullMonth } from "utils/Utils";
import { getUsuarios } from "services/Usuarios";
import useUsers from "hooks/useUsers";

const tableHeaders = ['POSTULANTE', 'INSTITUCIÓN', 'PUBLICACIÓN', 'FECHA']

const columns = [
  {
    title: 'Postulante',
    dataIndex: 'cuenta_id',
    key: 'nombre',
    render: (text, record) => <span>{record.postulante?.nombre} {record.postulante?.apellido}</span>,
  },
  {
    title: 'Insitución',
    dataIndex: 'cuenta_id',
    key: 'institucion',
    render: (text, record) => <span>{record.postulante?.nombre} {record.postulante?.apellido}</span>,
  },
  {
    title: 'Publicación',
    dataIndex: 'publicacion',
    key: 'publicacion',
    render: (text, record) => <Link to={`${routesNames.PUBLICATION}/${record.publicacion.id}`}>{record.publicacion?.nombre}</Link>,
  },
  {
    title: 'Fecha',
    dataIndex: 'created_at',
    render: (text) => {
      const date = new Date(text);
      return `${getFullDay(date, true)}/${getFullMonth(date)}/${date.getFullYear()}`
    }
  },
];

const CheckTable = () => {

  const { isLoading, response, getPostulateds } = usePublications()
  const { isLoading: isLoadingUsers, response: users, fetchData: getUsers, getInstituciones } = useUsers();
  const [instituciones, setInstituciones] = useState([])

  const fetchUsuarios = async () => {
    const data = await getInstituciones()
    setInstituciones(data)
  }

  useEffect(() => {
    fetchUsuarios()
  }, [])

  useEffect(() => {
    console.log(response)

    getPostulateds()
  }, [])


  if (isLoading) {
    return <Loader />
  }

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Últimas Postulaciones
        </div>

        <Link
          to={routesNames.POSTULATIONS}
          className={`text-sm hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-semibold transition duration-200`}
        >
          Ver todas
        </Link>
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            <tr>
              {tableHeaders.map((header, idx) => (
                <th
                  key={idx}
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                >
                  <p className="text-xs tracking-wide text-gray-600">
                    {header}
                  </p>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {response?.length > 0 && (
              response.map((pub, idx) => {
                const date = new Date(pub.created_at);
                return <tr key={idx}>

                  {/* Postulante */}
                  <td
                    className="pt-[14px] pb-[10px] sm:text-[14px]"
                  >
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {pub.postulante?.nombre} {pub.postulante?.apellido}
                    </p>
                  </td>

                  {/* Institución */}
                  <td
                    className="pt-[14px] pb-[10px] sm:text-[14px]"
                  >
                    {
                      pub?.publicacion_id !== null ?
                        <Link to={`${routesNames.USER}/${pub.publicacion?.cuenta?.user_id}`} className=" text-sm font-semibold text-blue-800 dark:text-white">
                          {pub.publicacion?.cuenta?.nombre}
                        </Link> :
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {
                            isLoadingUsers ? <span className="text-sm italic font-semibold text-main dark:text-white">Cargando...</span> :
                              <Link to={`${routesNames.USER}/${instituciones?.find(i => i?.cuenta?.id === pub?.cuenta_id)?.cuenta?.user_id}`} className=" text-sm font-semibold text-blue-800 dark:text-white">
                                {instituciones?.find(i => i?.cuenta?.id === pub?.cuenta_id)?.cuenta?.nombre}
                              </Link>
                          }
                        </p>
                    }
                  </td>


                  {/* Publicación */}
                  <td
                    className="pt-[14px] pb-[10px] sm:text-[14px]"
                  >
                    {
                      pub?.publicacion_id !== null ?
                        <Link to={`${routesNames.PUBLICATION}/${pub.publicacion?.id}`} className=" text-sm font-semibold text-blue-800 dark:text-white">
                          {pub.publicacion?.nombre}
                        </Link> :
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          Espontánea
                        </p>
                    }
                  </td>

                  {/* Fecha */}
                  <td
                    className="pt-[14px] pb-[10px] sm:text-[14px]"
                  >
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {getFullDay(date, true)}/{getFullMonth(date)}/{date.getFullYear()}
                    </p>
                  </td>
                </tr>
              })
            )}
          </tbody>
        </table>

      </div>
    </Card>
  );
};

export default CheckTable;
